import { History } from 'history';

export const safeGoBack = (
  history: History,
  fallbackPath: History.Path,
  replace: boolean = false
) => {
  if (history.length > 2) {
    return history.goBack();
  }
  return replace ? history.replace(fallbackPath) : history.push(fallbackPath);
};
