import { Header } from '@elotech/components';
import { ReactComponent as Logo } from 'common/assets/oxy-logo.svg';
import React from 'react';

type Props = {
  showMenu: boolean;
  currentUser: any;
  toggledSidebar: boolean;
  toggleSidebar: () => void;
};

const AppHeader: React.FC<Props> = props => {
  const { showMenu } = props;

  return (
    <Header
      title="Console"
      showToggleButton={showMenu}
      logoModulo={Logo}
      nomeModulo="Console"
      brasao={undefined}
      logoOxy
    >
      <Header.Features>
        <Header.Features.AccessibilityButton />
        <Header.Features.NotificationButton />
        <Header.Features.AjustesButton />
      </Header.Features>
      <Header.FeaturesContent>
        <Header.FeaturesContent.AccessibilityContent />
        <Header.FeaturesContent.NotificationContent />
        <Header.FeaturesContent.AjustesContent />
      </Header.FeaturesContent>
    </Header>
  );
};

export default AppHeader;
