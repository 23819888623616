export const GestaoBloqueioNotificationType = {
  TODOS_USUARIOS: 'TODOS_USUARIOS',
  ESCOLHER_USUARIOS_OU_GRUPOS: 'ESCOLHER_USUARIOS_OU_GRUPOS'
} as const;

export const GestaoBloqueioNotificationTypeLabels: Record<
  keyof typeof GestaoBloqueioNotificationType,
  string
> = {
  TODOS_USUARIOS: 'Todos usuários',
  ESCOLHER_USUARIOS_OU_GRUPOS: 'Escolher usuários e grupos específicos.'
};
export const GestaoBloqueioNotificationTypeValues = Object.entries(
  GestaoBloqueioNotificationTypeLabels
).map(([value, label]) => ({ value, label }));
