import {
  ButtonsAddCancel,
  Col,
  FormikAutocomplete,
  Row,
  Yup,
  formatUtils
} from '@elotech/components';
import { EntidadeService } from 'common/service';
import { Cliente, Entidade } from 'common/type';
import { Formik } from 'formik';
import React from 'react';

import ModulosPicker from '../../components/modulo-picker/ModulosPicker';
import { GestaoBloqueio, GestaoBloqueioEntidade } from '../../type';

const validationSchema = Yup.object().shape({
  entidade: Yup.object()
    .label('Entidade')
    .required()
});

type Props = {
  initialValue: GestaoBloqueioEntidade;
  onSubmit: (value: GestaoBloqueioEntidade) => void;
  onCancel: () => void;
  cliente: Cliente;
  formGestao: GestaoBloqueio;
};

const onSearch = (
  cliente: Cliente,
  search: string,
  formGestao: GestaoBloqueio
) => {
  return (
    `(cliente.id==${cliente.id});(nome==*${search}*,cnpj==${search}*)` +
    `${
      formGestao.entidades.length
        ? `;(id=out=(${formGestao.entidades
            .map(i => i.entidade.id)
            .join(',')}))`
        : ''
    }`
  );
};

const GestaoBloqueioItemForm: React.FC<Props> = props => {
  return (
    <Formik<GestaoBloqueioEntidade>
      initialValues={props.initialValue}
      onSubmit={props.onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {formProps => (
        <>
          <Row>
            <FormikAutocomplete<Entidade>
              name="entidade"
              label="Entidade"
              getOptionLabel={value =>
                `${value.nome} - ${formatUtils.formatCpfCnpj(value.cnpj)}`
              }
              getOptionValue={value => value.id}
              onSearch={(search: string) =>
                EntidadeService.findAll(
                  onSearch(props.cliente, search, props.formGestao),
                  undefined
                )
              }
              size={4}
            />
          </Row>
          {formProps.values.entidade && (
            <ModulosPicker
              modulos={formProps.values.modulos.flatMap(m => m.modulo)}
              onChange={values =>
                formProps.setFieldValue(
                  'modulos',
                  values.map(m => {
                    return { modulo: m };
                  }),
                  true
                )
              }
              modulosToFilter={formProps.values.entidade.modulos.flatMap(
                m => m.modulo
              )}
            />
          )}
          <Row className="mb-xs">
            <Col md={4} className="form-group">
              <ButtonsAddCancel
                onAdd={formProps.submitForm}
                onCancel={props.onCancel}
              />
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};

export default GestaoBloqueioItemForm;
