import {
  ActionButton,
  ActionsGroup,
  ArrayUtils,
  Button,
  Panel,
  SectionTitle,
  Table,
  formatUtils
} from '@elotech/components';
import { Cliente } from 'common/type';
import React, { useState } from 'react';

import { GestaoBloqueio, GestaoBloqueioEntidade } from '../../type';
import GestaoBloqueioItemForm from './GestaoBloqueioItemForm';

type Props = {
  entidades: GestaoBloqueioEntidade[];
  onChange: (values: GestaoBloqueioEntidade[]) => void;
  cliente: Cliente;
  formGestao: GestaoBloqueio;
};

const initGestaoBloqueioEntidade: GestaoBloqueioEntidade = {
  id: '',
  entidade: undefined!,
  modulos: []
};

const GestaoBloqueioItemList: React.FC<Props> = ({
  entidades,
  onChange,
  cliente,
  formGestao
}) => {
  const [showForm, setShowForm] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | undefined>(
    undefined
  );

  const onRemove = (index: number) => {
    const newValues = [
      ...entidades.slice(0, index),
      ...entidades.slice(index + 1)
    ];
    onChange(newValues);
  };

  const onEdit = (index: number) => {
    setShowForm(true);
    setEditingIndex(index);
  };

  const onNew = () => {
    setShowForm(true);
    setEditingIndex(undefined);
  };

  const clearForm = () => {
    setShowForm(false);
    setEditingIndex(undefined);
  };

  const onSubmitEntidade = (value: GestaoBloqueioEntidade) => {
    const newValues = ArrayUtils.addOrReplace(
      entidades,
      value,
      (_, index) => index === editingIndex
    );
    onChange(newValues);
    clearForm();
  };

  return (
    <>
      <SectionTitle hasButton>
        Entidades
        {!showForm && cliente?.id && (
          <Button onClick={onNew} iconPosition="left">
            <i className="fa fa-plus" />
            Novo
          </Button>
        )}
      </SectionTitle>
      {showForm && (
        <GestaoBloqueioItemForm
          initialValue={
            entidades[editingIndex ?? -1] ?? initGestaoBloqueioEntidade
          }
          onSubmit={onSubmitEntidade}
          onCancel={clearForm}
          cliente={cliente}
          formGestao={formGestao}
        />
      )}
      <Panel isTable>
        <Table values={entidades}>
          <Table.Column<GestaoBloqueioEntidade>
            header="Nome"
            value={value => value.entidade.nome}
          />
          <Table.Column<GestaoBloqueioEntidade>
            header="CNPJ"
            value={value => formatUtils.formatCpfCnpj(value.entidade.cnpj)}
          />
          <Table.Column<GestaoBloqueioEntidade>
            header=""
            value={(_, index) => (
              <ActionsGroup>
                <ActionButton
                  key="edit-button"
                  icon="pencil-alt"
                  label="Editar"
                  onClick={() => onEdit(index)}
                />
                <ActionButton
                  key="remove-button"
                  icon="trash-alt"
                  label="Remover"
                  onClick={() => onRemove(index)}
                />
              </ActionsGroup>
            )}
          />
        </Table>
      </Panel>
    </>
  );
};

export default GestaoBloqueioItemList;
