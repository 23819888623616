import { KeycloakService, RoleUsuarioDTO } from '@elotech/components';
import { AxiosPromise, AxiosResponse } from 'axios';

export const getRoles = (): AxiosPromise<RoleUsuarioDTO> => {
  const roles = KeycloakService.getInstance().resourceAccess;

  const clientRoles =
    (roles as any)['console-frontend']?.roles.map((role: string) => ({
      name: role
    })) ?? [];

  return Promise.resolve(({
    data: { clientRoles: clientRoles, realmRoles: [] }
  } as unknown) as AxiosResponse<RoleUsuarioDTO>);
};
