import { Alert, BlockDash, Container } from '@elotech/components';
import { ClienteService } from 'common/service';
import { DashboardDTO } from 'common/type';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

type Props = {
  history: Pick<History, 'push'>;
};

const emptyDashboard: DashboardDTO = {
  clientes: 0,
  entidades: 0
};

const HomePage: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardInfo, setDashboardInfo] = useState(emptyDashboard);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    ClienteService.getInformacoesDashboard()
      .then(response => setDashboardInfo(response.data))
      .catch(error => {
        Alert.error(
          { title: 'Erro ao buscar os dados para o dashboard' },
          error
        );
      })
      .finally(() => setLoading(false));
  }, []);

  const goToCliente = () => history.push('/clientes');

  return (
    <Container title="Início" icon="home">
      <BlockDash
        md={4}
        icon="fa fa-users"
        title="Clientes cadastrados"
        number={dashboardInfo.clientes}
        loading={loading}
        onClick={goToCliente}
      />
    </Container>
  );
};
export default HomePage;
