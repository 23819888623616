import { PageRequest, PagedResponse, Sort } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { GestaoBloqueio } from '../type/GestaoBloqueio';

const GESTAO_BLOQUEIO_API = '/console-api/api/gestoes-bloqueios';

export const findAll = (
  search: string,
  pagination?: PageRequest,
  sort?: Sort
): AxiosPromise<PagedResponse<GestaoBloqueio>> => {
  return axios.get(GESTAO_BLOQUEIO_API, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const save = (
  gestaoBloqueio: GestaoBloqueio
): AxiosPromise<GestaoBloqueio> =>
  gestaoBloqueio.id
    ? axios.put(`${GESTAO_BLOQUEIO_API}/${gestaoBloqueio.id}`, gestaoBloqueio)
    : axios.post(GESTAO_BLOQUEIO_API, gestaoBloqueio);

export const findById = (id: string): AxiosPromise<GestaoBloqueio> =>
  axios.get(`${GESTAO_BLOQUEIO_API}/${id}`);

export const deleteById = (id: string): AxiosPromise<void> =>
  axios.delete(`${GESTAO_BLOQUEIO_API}/${id}`);
