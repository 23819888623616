import {
  BreadCrumbRoute,
  NotFoundPage,
  ProtectedRoute
} from '@elotech/components';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CachePage from '../pages/cache/CachePage';
import ClienteFormPage from '../pages/cliente/ClienteFormPage';
import ClienteListPage from '../pages/cliente/ClienteListPage';
import ClienteViewPage from '../pages/cliente/ClienteViewPage';
import ModuloConfigPage from '../pages/cliente/ModuloConfigPage';
import GestaoBloqueioFormPage from '../pages/gestao-bloqueio/GestaoBloqueioFormPage';
import GestaoBloqueioListPage from '../pages/gestao-bloqueio/GestaoBloqueioListPage';
import GestaoBloqueioViewPage from '../pages/gestao-bloqueio/GestaoBloqueioViewPage';
import HomePage from '../pages/home/HomePage';
import { Roles } from '../roles/RolesConsole';

const routes: BreadCrumbRoute[] = [
  {
    title: 'Clientes',
    icon: 'users',
    path: '/clientes',
    component: ClienteListPage,
    exact: true,
    role: Roles.view_cliente.name
  },
  {
    title: 'Configuração dos módulos',
    icon: 'cog',
    parent: '/clientes/:id',
    path: '/clientes/:id/modulo-config',
    parentURLBuilder: (currentRoute: any) =>
      currentRoute.matchedURL.match(/\/clientes\/.*?\//)[0],
    component: ModuloConfigPage,
    role: Roles.clientes_modulo_config.name
  },
  {
    title: 'Cadastro de Cliente',
    icon: 'user',
    parent: '/clientes',
    path: '/clientes/:id',
    component: ClienteFormPage,
    exact: true,
    role: Roles.manage_cliente.name
  },
  {
    title: 'Detalhes',
    icon: 'th-list',
    parent: '/clientes',
    path: '/clientes/:id/view',
    component: ClienteViewPage,
    exact: true,
    role: Roles.view_cliente.name
  },
  {
    title: 'Gestões de Bloqueios',
    icon: 'lock',
    path: '/gestoes-bloqueios',
    component: GestaoBloqueioListPage,
    exact: true,
    role: Roles.gestao_bloqueio_view.name
  },
  {
    title: 'Cadastro de Gestões de Bloqueios',
    icon: 'lock',
    parent: '/gestoes-bloqueios',
    path: '/gestoes-bloqueios/:id',
    component: GestaoBloqueioFormPage,
    exact: true,
    role: Roles.gestao_bloqueio_manage.name
  },
  {
    title: 'Detalhes',
    icon: 'th-list',
    parent: '/gestoes-bloqueios',
    path: '/gestoes-bloqueios/:id/view',
    component: GestaoBloqueioViewPage,
    exact: true,
    role: Roles.gestao_bloqueio_view.name
  },
  {
    title: 'Cache',
    icon: 'eraser',
    path: '/cache',
    component: CachePage,
    exact: true,
    role: Roles.manage_cache.name
  }
];

const renderRoutes = (routes: any) =>
  routes.map((route: any) => <ProtectedRoute {...route} key={route.path} />);

const Routes = () => (
  <Switch>
    <Route path="/" component={HomePage} exact />
    {renderRoutes(routes)}
    <Route component={NotFoundPage} />
  </Switch>
);

const allRoutes = [...routes];

export { Routes as default, allRoutes };
