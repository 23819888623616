import {
  ActionButton,
  ActionsGroup,
  Button,
  FormikEditableInput,
  SectionTitle,
  Table
} from '@elotech/components';
import React from 'react';

import { ModuloConfigFlatAttributes } from './ModuloConfigPage';

type Props = {
  parentIndex: number;
  values: ModuloConfigFlatAttributes[];
  onChange: (value: ModuloConfigFlatAttributes[]) => void;
};

const ModuloConfigAttributes: React.FC<Props> = ({
  values,
  parentIndex,
  onChange
}) => {
  const onRemove = (removingIndex: number) => {
    onChange(values.filter((item, index) => index !== removingIndex));
  };

  const onNew = () => onChange([...values, { key: '', value: '' }]);
  return (
    <>
      <SectionTitle hasButton marginTop={'10px'}>
        Atributos
        <Button onClick={onNew} iconPosition="left">
          <i className="fa fa-plus" />
          Novo
        </Button>
      </SectionTitle>
      <Table
        values={values}
        keyExtractor={(value, index) => `${value.key}-${index}`}
      >
        <Table.Column<ModuloConfigFlatAttributes>
          header={'Chave'}
          value={(item, index) => (
            <FormikEditableInput
              autoFocus
              name={`[${parentIndex}].flatAttributes.[${index}].key`}
            />
          )}
        />
        <Table.Column<ModuloConfigFlatAttributes>
          header={'Valor'}
          value={(item, index) => (
            <FormikEditableInput
              autoFocus
              name={`[${parentIndex}].flatAttributes.[${index}].value`}
            />
          )}
        />
        <Table.Column<ModuloConfigFlatAttributes>
          header={''}
          value={(item, index) => (
            <ActionsGroup>
              <ActionButton
                key="remove-button"
                icon="trash-alt"
                label="Remover"
                onClick={() => onRemove(index)}
              />
            </ActionsGroup>
          )}
        />
      </Table>
    </>
  );
};

export default ModuloConfigAttributes;
