import {
  Alert,
  BasicInput,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FAB,
  FormikInputInteger,
  Loading,
  Panel,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { ClienteService } from 'common/service';
import { Cliente, ModuloConfig } from 'common/type';
import { safeGoBack } from 'common/utils/RouteUtils';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import ModuloConfigAttributes from './ModuloConfigAttributes';

type Props = {};

export type ModuloConfigFlatAttributes = {
  key: string;
  value: string;
};
type ModuloConfigForm = ModuloConfig & {
  flatAttributes: ModuloConfigFlatAttributes[];
};
const defaultValue = (modulo: string): ModuloConfigForm => ({
  id: '',
  clienteId: '',
  modulo,
  jdbcUrl: '',
  username: '',
  password: '',
  poolSize: 10,
  attributes: {},
  flatAttributes: []
});

const attributesToArray = (
  modulo?: ModuloConfig
): ModuloConfigForm | undefined => {
  if (!modulo) {
    return undefined;
  }

  return {
    ...modulo,
    flatAttributes: Object.entries(modulo.attributes).map(([key, value]) => ({
      key,
      value
    }))
  };
};

const arrayToAttributes = (modulo: ModuloConfigForm): ModuloConfig => {
  const { flatAttributes, ...moduloSemFlatAttributes } = modulo;
  const attributes = flatAttributes.reduce(
    (result, current) => ({ ...result, [current.key]: current.value }),
    {}
  );
  return { ...moduloSemFlatAttributes, attributes };
};
const buildModulos = (
  response: AxiosResponse<ModuloConfig[]>,
  modulosCadastrados: string[]
) => {
  const modulosAgrupados: {
    [key: string]: ModuloConfig;
  } = response.data.reduce(
    (result, current) => ({ ...result, [current.modulo]: current }),
    {}
  );

  return modulosCadastrados.map(
    modulo =>
      attributesToArray(modulosAgrupados[modulo]) ?? defaultValue(modulo)
  );
};

// Foi preciso criar esse Validation schema dinamico pois a funcao que valida o objeto dentro do formik altera o formato quando o valor é um array
// Ex: [{valor: 10, nome: 'a'}, {valor: 15, nome: 'b'}] vira {0: {valor: 10, nome: 'a'}, 1: {valor: 15, nome: 'b'}}
// Essa funcao cria um validation schema dinamico, que recebe o array original, e gera um validationSchema onde cada posicao do array é validada com o validationSchema do objeto
const ArrayValidationSchema = Yup.lazy(
  (obj: Record<string, ModuloConfigForm>) => {
    return Object.keys(obj).reduce((result, key) => {
      return result.concat(
        Yup.object().shape({
          [key]: ModuloConfigFormValidationSchema
        })
      );
    }, Yup.object<Record<string, ModuloConfigForm>>());
  }
);

const ModuloConfigFormValidationSchema = Yup.object().shape({
  jdbcUrl: Yup.string().label('url'),
  username: Yup.string()
    .label('Usuário')
    .when('jdbcUrl', (urlJdbc: string, schema: Yup.StringSchema) =>
      !!urlJdbc ? schema.required() : schema
    ),
  password: Yup.string()
    .label('Senha')
    .when('jdbcUrl', (urlJdbc: string, schema: Yup.StringSchema) =>
      !!urlJdbc ? schema.required() : schema
    ),
  poolSize: Yup.number()
    .label('Quantidade de conexões')
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v))
    .min(1),
  flatAttributes: Yup.array().of(
    Yup.object().shape({
      key: Yup.string()
        .label('Chave')
        .required(),
      value: Yup.string()
        .label('Valor')
        .required()
    })
  )
});

const ModuloConfigPage: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState<Cliente>();
  const [modulos, setModulos] = useState<ModuloConfigForm[]>([]);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setLoading(true);
    ClienteService.findById(id)
      .then(response => {
        setCliente(response.data);
        return response.data;
      })
      .catch(error => {
        Alert.error({ title: `Erro ao buscar o cliente ${id}` }, error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (cliente) {
      ClienteService.getModulosConfig(cliente.id)
        .then(modulos =>
          buildModulos(modulos, [
            ...new Set<string>(
              cliente.entidades.flatMap(e => e.modulos.map(m => m.modulo))
            )
          ])
        )
        .then(modulos => setModulos(modulos))
        .catch(error => {
          Alert.error(
            { title: `Erro ao buscar os módulos do cliente ${cliente.id}` },
            error
          );
        });
    }
  }, [cliente]);

  const onSave = (value: ModuloConfigForm[]) => {
    const valueToSave = value.map(arrayToAttributes);

    setLoading(true);
    ClienteService.saveModuloConfig(id, valueToSave)
      .then(() => {
        safeGoBack(history, '/clientes');
      })
      .catch(error => {
        setLoading(false);
        Alert.error({ title: 'Erro ao salvar as configurações.' }, error);
      });
  };
  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      {cliente && (
        <>
          <DisplayDataGrid column>
            <Row>
              <DisplayDataItem title="Nome" md={9}>
                {cliente.nome}
              </DisplayDataItem>
              <DisplayDataItem title="Tenant" md={3}>
                {cliente.tenant}
              </DisplayDataItem>
            </Row>
            {cliente.entidades.map(entidade => {
              return (
                <>
                  <Row>
                    <DisplayDataItem title={entidade.nome} md={12}>
                      {entidade.modulos?.map(m => m.modulo).join(', ')}
                    </DisplayDataItem>
                  </Row>
                </>
              );
            })}
          </DisplayDataGrid>
          <Formik
            enableReinitialize
            initialValues={modulos}
            onSubmit={onSave}
            validationSchema={ArrayValidationSchema}
          >
            {formProps => (
              <>
                <SectionTitle>Configurações</SectionTitle>
                {formProps.values.map((modulo, index) => (
                  <Panel title={modulo.modulo} key={modulo.modulo}>
                    <Row>
                      <BasicInput
                        name={`[${index}].jdbcUrl`}
                        label={'URL do JDBC'}
                        size={6}
                      />
                      <BasicInput
                        name={`[${index}].username`}
                        label={'Usuário do banco'}
                        size={2}
                      />
                      <BasicInput
                        name={`[${index}].password`}
                        label={'Senha do banco'}
                        size={2}
                        type={'password'}
                      />
                      <FormikInputInteger
                        name={`[${index}].poolSize`}
                        label="Quantidade de conexões"
                        size={2}
                      />
                    </Row>
                    <ModuloConfigAttributes
                      values={modulo.flatAttributes}
                      parentIndex={index}
                      onChange={(values: ModuloConfigFlatAttributes[]) => {
                        formProps.setFieldValue(
                          `[${index}].flatAttributes` as any,
                          values,
                          true
                        );
                      }}
                    />
                  </Panel>
                ))}
                <div className="btn-save">
                  <FAB
                    icon={'check'}
                    title={'Salvar'}
                    onClick={formProps.submitForm}
                  />
                </div>
              </>
            )}
          </Formik>
        </>
      )}
    </Container>
  );
};

export default ModuloConfigPage;
