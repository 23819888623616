import { ValidationUtils } from '@elotech/components';
import * as Yup from 'yup';

export const entidadeValidationSchema = Yup.object().shape({
  nome: Yup.string()
    .max(100, 'Ultrapassou o máximo de 100 caracteres')
    .required('O nome é obrigatório'),
  cnpj: Yup.string()
    .max(20, 'Ultrapassou o máximo de 20 caracteres')
    .required('O CNPJ é obrigatório')
    .test('validacaoCNPJ', 'CNPJ inválido', value =>
      ValidationUtils.validateCnpj(value)
    ),
  codigo: Yup.number(),
  endereco: Yup.object().shape({
    cep: Yup.string().test(
      'tamanhocep',
      'CEP inválido',
      value => value && value.replace('-', '').match(/\d{8}/)
    ),
    numero: Yup.string().max(10, 'Ultrapassou o máximo de 10 caracteres'),
    logradouro: Yup.string().max(100, 'Ultrapassou o máximo de 100 caracteres'),
    bairro: Yup.string().max(100, 'Ultrapassou o máximo de 100 caracteres'),
    cidade: Yup.string().max(60, 'Ultrapassou o máximo de 60 caracteres'),
    uf: Yup.string(),
    ibge: Yup.string().max(60, 'Ultrapassou o máximo de 60 caracteres')
  })
});

export const clienteValidationSchema = Yup.object().shape({
  tenant: Yup.string()
    .max(40, 'Ultrapassou o máximo de 40 caracteres')
    .required('Identificador é obrigatório'),
  nome: Yup.string()
    .max(100, 'Ultrapassou o máximo de 100 caracteres')
    .required('Nome é obrigatório')
});
