import { Endereco } from './Endereco';

export type Entidade = {
  id: string;
  codigo?: number;
  nome: string;
  ativo: boolean;
  cnpj: string;
  endereco: Endereco;
  passivelDeVinculo: boolean;
  brasao: undefined;
  modulos: EntidadeModulo[];
};

export const initEntidade: Entidade = {
  id: '',
  nome: '',
  ativo: true,
  cnpj: '',
  endereco: {
    id: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    ibge: ''
  },
  modulos: [],
  passivelDeVinculo: false,
  brasao: undefined
};

export type EntidadeModulo = {
  modulo: string;
};
