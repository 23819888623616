import {
  BasicInput,
  ButtonsAddCancel,
  Col,
  FormikCheckBox,
  FormikInputInteger,
  Masks,
  Row
} from '@elotech/components';
import { FormikUfSelect } from 'common/components';
import { CepService } from 'common/service';
import { Entidade } from 'common/type';
import { viaCepToEndereco } from 'common/utils/CepUtils';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import MaskedInput from 'react-text-mask';

import ModulosPicker from '../../components/modulo-picker/ModulosPicker';
import { entidadeValidationSchema } from './validationSchema';

type Props = {
  initialValue: Entidade;
  onSubmit: (value: Entidade) => void;
  onCancel: () => void;
};

const EntidadeForm: React.FC<Props> = props => {
  const onChangeCep = (e: React.ChangeEvent<any>, form: FormikProps<any>) => {
    const { value } = e.target;

    form.setFieldValue('endereco.cep', value, true);
    form.setFieldTouched('endereco.cep', true, true);

    const onlyNumbers = value.replace(/[^\d]/g, '');
    if (onlyNumbers.length === 8) {
      CepService.findByCep(onlyNumbers).then(response => {
        form.setFieldValue('endereco', viaCepToEndereco(response.data), true);
      });
    }
  };
  return (
    <Formik<Entidade>
      initialValues={props.initialValue}
      onSubmit={props.onSubmit}
      validationSchema={entidadeValidationSchema}
    >
      {formProps => (
        <>
          <Row>
            <BasicInput
              name="cnpj"
              label="CNPJ"
              render={({ field }) => (
                <MaskedInput
                  {...field}
                  onChange={({ target }) => {
                    formProps.setFieldValue(
                      'cnpj',
                      target.value.replace(/\D/g, ''),
                      true
                    );
                    formProps.setFieldTouched('cnpj', true);
                  }}
                  autoFocus
                  mask={Masks.MASK_CNPJ}
                  maxLength={19}
                />
              )}
            />
            <BasicInput name={'nome'} label="Nome" size={4} />
            <BasicInput name={'codigo'} label="Código" size={1} />
            <FormikCheckBox
              id="entidade-ativo"
              name="ativo"
              label="Ativo"
              size={2}
              noLabel={false}
            />
          </Row>
          <Row>
            <BasicInput
              name={'endereco.cep'}
              label={'CEP'}
              type="text"
              size={2}
              render={({ field }) => (
                <MaskedInput
                  {...field}
                  mask={Masks.MASK_CEP}
                  id={field.name}
                  placeholder="Ex. 87047-000"
                  onChange={e => onChangeCep(e, formProps)}
                  className={field.className}
                  value={
                    !field.value && field.value === undefined ? '' : field.value
                  }
                />
              )}
            />
            <BasicInput
              name="endereco.logradouro"
              label="Logradouro"
              size={6}
            />
            <BasicInput name="endereco.numero" label="Número" size={1} />
            <BasicInput
              name="endereco.complemento"
              label="Complemento"
              size={4}
            />
          </Row>
          <Row>
            <BasicInput name="endereco.bairro" label="Bairro" size={4} />
            <BasicInput name="endereco.cidade" label="Cidade" size={3} />
            <FormikUfSelect name="endereco.uf" />
            <FormikInputInteger name="endereco.ibge" label="IBGE" size={2} />
          </Row>
          <ModulosPicker
            modulos={formProps.values.modulos.map(m => m.modulo)}
            onChange={values =>
              formProps.setFieldValue(
                'modulos',
                values.map(m => {
                  return { modulo: m };
                }),
                true
              )
            }
          />
          <Row className="mb-xs">
            <Col md={4} className="form-group">
              <ButtonsAddCancel
                onAdd={formProps.submitForm}
                onCancel={props.onCancel}
              />
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};

export default EntidadeForm;
