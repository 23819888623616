import {
  ActionButton,
  ActionsGroup,
  Alert,
  Col,
  Container,
  FAB,
  FabSpeedDial,
  Loading,
  ObjectUtils,
  Panel,
  ProtectedComponent,
  Row,
  SearchPagination,
  SearchPanel,
  Table,
  usePagedQuery
} from '@elotech/components';
import React, { useEffect, useState } from 'react';
import { FormattedDate } from 'react-intl';

import { Roles } from '../../roles/RolesConsole';
import { GestaoBloqueioService } from '../../service';
import { GestaoBloqueio } from '../../type';

type Props = {};

const buildFilter = (search: string) => {
  return `(cliente.nome==*${search}*,cliente.tenant==${search}*)`;
};

const GestaoBloqueioListPage: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');

  const {
    doSearch,
    values,
    loading,
    pagination,
    doSortChange,
    doPagedSearch
  } = usePagedQuery({
    search: GestaoBloqueioService.findAll,
    onError: error => {
      Alert.error(
        {
          title: 'Erro ao buscar as gestões de bloqueios.'
        },
        error
      );
    }
  });

  useEffect(() => {
    doSearch(filter);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRemove = (gestao: GestaoBloqueio) => {
    Alert.question({
      title: `Deseja inativar a suspensão de ${gestao.cliente?.nome}?`
    }).then((result: any) => {
      if (result.value) {
        setIsLoading(true);
        GestaoBloqueioService.deleteById(gestao.id)
          .then(
            () => {
              Alert.success({
                title: 'Inativado com sucesso.'
              });
              doSearch();
            },
            error => {
              Alert.error({}, error);
            }
          )
          .finally(() => setIsLoading(false));
      }
    });
  };

  const onFilter = () => {
    doSearch(buildFilter(filter));
  };

  return (
    <Container breadcrumb>
      <Loading loading={isLoading} />
      <Panel isTable>
        <SearchPanel>
          <Row className="mb-xs">
            <Col className="form-group">
              <input
                autoFocus
                value={filter}
                name="searchValue"
                placeholder="Digite o filtro"
                onChange={e => setFilter(e.target.value)}
                style={{ width: `calc(100% - 82px)`, height: '28px' }}
                onKeyDown={e => e.key === 'Enter' && onFilter()}
              />
              <button
                data-test-id="buttonAdicionarFiltro"
                className="btn filter"
                type="button"
                onClick={onFilter}
              >
                Filtrar
              </button>
            </Col>
          </Row>
        </SearchPanel>
        <Table
          values={values}
          loading={loading}
          sortOnHeaderColumnClick={doSortChange}
        >
          <Table.Column<GestaoBloqueio>
            header="Nome"
            value={gestaoBloqueio => gestaoBloqueio.cliente?.nome}
            sortable
            name="cliente.nome"
          />

          <Table.Column<GestaoBloqueio>
            header="Tenant"
            value={gestaoBloqueio => gestaoBloqueio.cliente?.tenant}
            sortable
            name="cliente.tenant"
          />

          <Table.Column<GestaoBloqueio>
            header="Data de inicio da notificação"
            value={gestaoBloqueio => (
              <FormattedDate
                value={gestaoBloqueio.dataInicioNotificacao}
                timeZone={'UTC'}
              />
            )}
            sortable
            name="dataInicioNotificacao"
          />
          <Table.Column<GestaoBloqueio>
            header="Data de suspensão"
            value={gestaoBloqueio => (
              <FormattedDate
                value={gestaoBloqueio.dataSuspensao}
                timeZone={'UTC'}
              />
            )}
            sortable
            name="dataSuspensao"
          />
          <Table.Column<GestaoBloqueio>
            header="Ativa"
            value={gestaoBloqueio =>
              ObjectUtils.booleanToSimNao(gestaoBloqueio.ativo)
            }
          />
          <Table.Column<GestaoBloqueio>
            header=""
            value={gestao => (
              <ActionsGroup>
                <ActionButton
                  key="view-button"
                  icon="eye"
                  label="Visualizar"
                  path={`/gestoes-bloqueios/${gestao.id}/view`}
                />
                <ProtectedComponent role={Roles.gestao_bloqueio_manage.name}>
                  <ActionButton
                    key="edit-button"
                    icon="pencil-alt"
                    label="Editar"
                    path={`/gestoes-bloqueios/${gestao.id}`}
                  />
                </ProtectedComponent>
                <ProtectedComponent role={Roles.gestao_bloqueio_remove.name}>
                  {gestao.ativo && (
                    <ActionButton
                      key="remove-button"
                      icon="times"
                      label="Inativar"
                      onClick={() => onRemove(gestao)}
                    />
                  )}
                </ProtectedComponent>
              </ActionsGroup>
            )}
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      <ProtectedComponent role={Roles.gestao_bloqueio_manage.name}>
        <FabSpeedDial icon="ellipsis-v" title="Ações">
          <FAB
            icon="plus"
            title="Novo Bloqueio"
            path="/gestoes-bloqueios/new"
            small
            ro
          />
        </FabSpeedDial>
      </ProtectedComponent>
    </Container>
  );
};

export default GestaoBloqueioListPage;
