import { Alert, Container } from '@elotech/components';
import React, { useState } from 'react';

import { ClienteService } from '../../service';

type Props = {};

const CachePage: React.FC<Props> = () => {
  const [clearCache, setClearCache] = useState(false);

  const clearConfigCache = () => {
    setClearCache(true);
    ClienteService.clearCache()
      .then(() => {
        Alert.success({
          title: 'Cache da configuração limpa com sucesso.'
        });
      })
      .catch(error => {
        Alert.error({ title: `Erro ao limpar cache da configuração` }, error);
      })
      .finally(() => setClearCache(false));
  };

  return (
    <Container breadcrumb>
      <button
        className="btn outlined module-color icon-left inline"
        onClick={clearConfigCache}
      >
        <i className={`fa fa-sync-alt ${clearCache ? 'fa-spin' : ''}`} />
        Limpar cache das configurações
      </button>
    </Container>
  );
};

export default CachePage;
