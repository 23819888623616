import { BasicInput } from '@elotech/components';
import React, { ComponentProps } from 'react';

type BasicInputProps = ComponentProps<typeof BasicInput>;

const FormikInputTime: React.FC<BasicInputProps> = props => {
  return (
    <BasicInput
      {...props}
      render={({ field }) => (
        <input
          id={field.name}
          type="time"
          max="9999-12-31"
          autoComplete="off"
          {...field}
        />
      )}
    />
  );
};

export default FormikInputTime;
