import {
  Alert,
  BasicInput,
  Container,
  FAB,
  FormikCheckBox,
  FormikSelect,
  Loading,
  Row
} from '@elotech/components';
import { PartialPick } from '@elotech/components/dist/types/type';
import { FormikUfSelect } from 'common/components';
import { ClienteService } from 'common/service';
import { Cliente } from 'common/type';
import { safeGoBack } from 'common/utils/RouteUtils';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import EntidadeList from './EntidadeList';
import { clienteValidationSchema } from './validationSchema';

type ClienteForm = PartialPick<Cliente, 'id'>;

const initialValue: ClienteForm = {
  id: undefined,
  nome: '',
  tenant: '',
  uf: 'PR',
  ativo: true,
  onPremise: false,
  multiRealm: false,
  entidades: [],
  contratante: ''
};

const ClienteFormPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState<ClienteForm | undefined>(undefined);
  const [contratantes, setContratantes] = useState<string[]>([]);

  useEffect(() => {
    ClienteService.getContratantes()
      .then(response => setContratantes(response.data))
      .catch(error => {
        Alert.error({ title: `Erro ao buscar os contratantes` }, error);
      });
  }, []);

  useEffect(() => {
    if (id === 'novo') {
      return setCliente(initialValue);
    }
    setLoading(true);
    ClienteService.findById(id)
      .then(response => setCliente(response.data))
      .catch(error => {
        Alert.error({ title: `Erro ao buscar o cliente ${id}` }, error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const onSubmit = (value: ClienteForm) => {
    setLoading(true);

    ClienteService.save(value as Cliente)
      .then(({ data }) => {
        if (id === 'novo') {
          return history.push(`/clientes/${data.id}/view`);
        }

        safeGoBack(history, '/clientes');
      })
      .catch(error => {
        setLoading(false);
        Alert.error({ title: 'Erro ao salvar o cadastro' }, error);
      });
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      {cliente && (
        <Formik<ClienteForm>
          initialValues={cliente}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={clienteValidationSchema}
        >
          {formProps => (
            <>
              <Row>
                <BasicInput name="nome" label="Nome" size={7} />
                <BasicInput name="tenant" label="Identificador" />
                <FormikUfSelect name="uf" />
              </Row>
              <Row>
                <FormikSelect<any>
                  size={4}
                  label="Contratante"
                  getOptionValue={(item: any) => item.identificador}
                  getOptionLabel={(item: any) => item.identificador}
                  name="contratante"
                  options={contratantes}
                />
              </Row>
              <Row>
                <FormikCheckBox
                  id="ativo"
                  name="ativo"
                  label="Ativo"
                  size={2}
                  noLabel={false}
                />
                <FormikCheckBox
                  id="onPremise"
                  name="onPremise"
                  label="On-Premise"
                  size={2}
                  noLabel={false}
                />
                <FormikCheckBox
                  id="multiRealm"
                  name="multiRealm"
                  label="Multi-Realm"
                  size={2}
                  noLabel={false}
                />
              </Row>
              <EntidadeList
                entidades={formProps.values.entidades}
                onChange={values =>
                  formProps.setFieldValue('entidades', values, true)
                }
              />
              <div className="btn-save">
                <FAB
                  data-test-id="btn-save"
                  icon="check"
                  onClick={formProps.submitForm}
                  title="Salvar"
                />
              </div>
            </>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default ClienteFormPage;
