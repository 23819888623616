/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Alert,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FAB,
  FabSpeedDial,
  Icon,
  Loading,
  Panel,
  Row,
  SectionTitle,
  formatUtils,
  useAuth
} from '@elotech/components';
import React, { useEffect, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { useHistory, useParams } from 'react-router';

import ModulosPicker from '../../components/modulo-picker/ModulosPicker';
import { Roles } from '../../roles/RolesConsole';
import { GestaoBloqueioService } from '../../service';
import { GestaoBloqueio } from '../../type';

const GestaoBloqueioViewPage = () => {
  const { hasRole } = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [gestao, setGestao] = useState<GestaoBloqueio | undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    GestaoBloqueioService.findById(id)
      .then(response => setGestao(response.data))
      .catch(error =>
        Alert.error({ title: `Erro ao buscar gestão de bloqueio ${id}` }, error)
      )
      .finally(() => setLoading(false));
  }, [id]);

  const getActionsButtons = () => {
    return (
      <FabSpeedDial icon="ellipsis-v" title="Ações">
        {hasRole(Roles.manage_cliente.name) ? (
          <FAB
            icon="pencil-alt"
            onClick={() => history.push(`/gestoes-bloqueios/${gestao?.id}`)}
            title="Editar"
            disabled={false}
          />
        ) : (
          undefined
        )}
      </FabSpeedDial>
    );
  };

  return (
    <>
      <Container breadcrumb>
        <Loading loading={loading} />
        {gestao && (
          <>
            <DisplayDataGrid column>
              <Row>
                <DisplayDataItem title="Nome" md={6}>
                  {gestao.cliente?.nome}
                </DisplayDataItem>
                <DisplayDataItem title="Identificador" md={2}>
                  <a
                    href={
                      window.location.origin?.replace(
                        'console',
                        gestao.cliente?.tenant
                      ) || '#'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {gestao.cliente?.tenant} &nbsp;
                  </a>
                  <Icon
                    icon="external-link-alt"
                    darkPrimary
                    hoverColor="white"
                  />
                </DisplayDataItem>
                <DisplayDataItem title="Contratante" md={2}>
                  {gestao.cliente?.contratante}
                </DisplayDataItem>
                <DisplayDataItem title="UF" md={1}>
                  {gestao.cliente?.uf}
                </DisplayDataItem>
                <DisplayDataItem title="Multi realm" md={1}>
                  {gestao.cliente?.multiRealm ? 'Sim' : 'Não'}
                </DisplayDataItem>
              </Row>
              <Row>
                <DisplayDataItem title="Data de início da notificação" md={2}>
                  <FormattedDate
                    value={gestao.dataInicioNotificacao}
                    timeZone={'UTC'}
                  />
                </DisplayDataItem>
                <DisplayDataItem title="Data de suspensão" md={2}>
                  <FormattedDate
                    value={gestao.dataSuspensao}
                    timeZone={'UTC'}
                  />
                </DisplayDataItem>
                <DisplayDataItem title="Horário da notificação" md={2}>
                  {gestao.horaNotificacao}
                </DisplayDataItem>
                <DisplayDataItem title="Ativo" md={1}>
                  {gestao.ativo ? 'Sim' : 'Não'}
                </DisplayDataItem>
              </Row>
              <Row>
                <DisplayDataItem title="Título" md={12}>
                  {gestao.titulo}
                </DisplayDataItem>
              </Row>
              <Row>
                <DisplayDataItem title="Mensagem" md={12}>
                  {gestao.mensagem}
                </DisplayDataItem>
              </Row>
            </DisplayDataGrid>

            <SectionTitle>Entidades Suspensas</SectionTitle>

            {gestao.entidades.map(gestaoEntidade => (
              <Panel
                expansible
                title={gestaoEntidade.entidade?.nome}
                key={gestaoEntidade.entidade?.id}
                collapsed={true}
              >
                <DisplayDataGrid column>
                  <Row>
                    <DisplayDataItem title="Código" md={1}>
                      {gestaoEntidade.entidade?.codigo}
                    </DisplayDataItem>
                    <DisplayDataItem title="Identificador" md={3}>
                      {formatUtils.formatCpfCnpj(gestaoEntidade.entidade?.cnpj)}
                    </DisplayDataItem>
                    <DisplayDataItem title="Nome" md={8}>
                      {gestaoEntidade.entidade?.nome}
                    </DisplayDataItem>
                  </Row>
                  <Row>
                    <DisplayDataItem title="CEP" md={2}>
                      {gestaoEntidade.entidade?.endereco?.cep}
                    </DisplayDataItem>
                    <DisplayDataItem title="Logradouro" md={4}>
                      {gestaoEntidade.entidade?.endereco?.logradouro}
                    </DisplayDataItem>
                    <DisplayDataItem title="Complemento" md={4}>
                      {gestaoEntidade.entidade?.endereco?.complemento}
                    </DisplayDataItem>
                    <DisplayDataItem title="Número" md={2}>
                      {gestaoEntidade.entidade?.endereco?.numero}
                    </DisplayDataItem>
                  </Row>
                  <Row>
                    <DisplayDataItem title="Bairro" md={4}>
                      {gestaoEntidade.entidade?.endereco?.bairro}
                    </DisplayDataItem>
                    <DisplayDataItem title="Cidade" md={4}>
                      {gestaoEntidade.entidade?.endereco?.cidade}
                    </DisplayDataItem>
                    <DisplayDataItem title="UF" md={2}>
                      {gestaoEntidade.entidade?.endereco?.uf}
                    </DisplayDataItem>
                    <DisplayDataItem title="IBGE" md={2}>
                      {gestaoEntidade.entidade?.endereco?.ibge}
                    </DisplayDataItem>
                  </Row>
                </DisplayDataGrid>
                <ModulosPicker
                  modulos={gestaoEntidade?.modulos.map(e => e.modulo)}
                  modulosToFilter={gestaoEntidade?.modulos.map(e => e.modulo)}
                  readonly
                ></ModulosPicker>
              </Panel>
            ))}
          </>
        )}
        <div className="btn-save">{getActionsButtons()}</div>
      </Container>
    </>
  );
};

export default GestaoBloqueioViewPage;
