import {
  Alert,
  Button,
  Checkbox,
  Col,
  Row,
  SectionTitle
} from '@elotech/components';
import { ModuloDTO } from 'common/type';
import React, { useEffect, useState } from 'react';

import { ModuloService } from '../../service';

type Props = {
  modulos: string[];
  modulosToFilter?: string[];
  readonly?: boolean;
  onChange?: (modulos: string[]) => void;
};

const ModulosPicker: React.FC<Props> = props => {
  const [dtos, setDtos] = useState<ModuloDTO[]>([]);

  useEffect(() => {
    ModuloService.getModulos()
      .then(response =>
        props.modulosToFilter
          ? setDtos(
              response.data.filter(m =>
                props.modulosToFilter!.includes(m.valor)
              )
            )
          : setDtos(response.data)
      )
      .catch(error => {
        Alert.error({ title: 'Erro ao buscar os módulos' }, error);
      });
  }, [props.modulosToFilter]);

  const onChange = (checked: boolean, valor: string) => {
    if (props.onChange && !props.readonly) {
      const newValue = checked
        ? [...props.modulos, valor]
        : props.modulos.filter(modulo => modulo !== valor);

      props.onChange(newValue);
    }
  };

  const selecionarTodos = () => {
    if (isReadOnly()) {
      props.onChange!(dtos.map(m => m.valor));
    }
  };

  const isReadOnly = (): boolean => !!props.onChange && !props.readonly;

  return (
    <>
      <SectionTitle hasButton>
        Módulos
        {isReadOnly() && (
          <Button onClick={selecionarTodos} iconPosition="left">
            Selecionar Todos
          </Button>
        )}
      </SectionTitle>
      <Row>
        {dtos.map(dto => (
          <Col md={3} key={dto.valor}>
            <Checkbox
              id={dto.valor}
              name={`${dto.valor}`}
              checked={props.modulos?.includes(dto.valor)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange(e.target.checked, dto.valor)
              }
              label={dto.descricao}
              readonly={props.readonly}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ModulosPicker;
