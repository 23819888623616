import {
  Alert,
  BasicInput,
  Container,
  DateUtils,
  FAB,
  FormikAutocomplete,
  FormikCheckBox,
  FormikInputDate,
  FormikTextArea,
  Loading,
  Row,
  Yup
} from '@elotech/components';
import { FormikInputTime } from 'common/components';
import { ClienteService } from 'common/service';
import { Cliente } from 'common/type';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { GestaoBloqueioService } from '../../service';
import { GestaoBloqueio } from '../../type';
import { GestaoBloqueioNotificationType } from '../../type/GestaoBloqueioNotificationType';
import GestaoBloqueioItemList from './GestaoBloqueioItemList';

type Props = {};

const validationSchema = Yup.object().shape({
  cliente: Yup.object()
    .label('Cliente')
    .required(),
  titulo: Yup.string()
    .label('Título')
    .max(100)
    .required(),
  mensagem: Yup.string()
    .label('Mensagem')
    .max(4000)
    .required(),
  dataInicioNotificacao: Yup.string()
    .label('Data de início da notificação')
    .required(),
  dataSuspensao: Yup.string()
    .label('Data de suspensão')
    .required(),
  horaNotificacao: Yup.string()
    .label('Horário da notificação')
    .required()
});

const initialValue: GestaoBloqueio = {
  id: '',
  cliente: undefined!,
  titulo: '',
  mensagem: '',
  dataInicioNotificacao: DateUtils.getTodayDate(),
  dataSuspensao: DateUtils.getTodayDateIncDays(7),
  ativo: true,
  horaNotificacao: '08:00:00',
  tipoNotificacao: GestaoBloqueioNotificationType.TODOS_USUARIOS,
  entidades: []
};

const GestaoBloqueioFormPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [gestaoBloqueio, setGestaoBloqueio] = useState<GestaoBloqueio>(
    initialValue
  );
  const history = useHistory();

  useEffect(() => {
    if (id === 'new') {
      return setGestaoBloqueio(initialValue);
    }

    setLoading(true);
    GestaoBloqueioService.findById(id)
      .then(response => setGestaoBloqueio(response.data))
      .catch(error => {
        Alert.error(
          {
            title: `Erro ao buscar a gestão de bloqueio ${id}`
          },
          error
        );
      })
      .finally(() => setLoading(false));
  }, [id]);

  const submit = async (value: GestaoBloqueio) => {
    setLoading(true);
    GestaoBloqueioService.save(value)
      .then(() => {
        history.push('/gestoes-bloqueios');
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao salvar a gestão de bloqueio' }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <Formik
        onSubmit={submit}
        initialValues={gestaoBloqueio}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {formProps => (
          <>
            <Row>
              <FormikAutocomplete<Cliente>
                name="cliente"
                label="Cliente"
                getOptionLabel={value => `${value.nome} - ${value.tenant}`}
                getOptionValue={value => value.id}
                onSearch={(search: string) =>
                  ClienteService.search(search, undefined)
                }
                size={4}
              />
              <FormikInputDate
                name="dataInicioNotificacao"
                label="Data de início da notificação"
              />
              <FormikInputDate name="dataSuspensao" label="Data de suspensão" />
              <FormikInputTime
                name="horaNotificacao"
                label="Horário da notificação"
              />
              <FormikCheckBox name="ativo" label="Ativo" noLabel={false} />
            </Row>
            <Row>
              <BasicInput
                name="titulo"
                label="Título"
                size={8}
                maxLength={100}
              />
            </Row>
            <Row>
              <FormikTextArea name="mensagem" label="Mensagem" size={12} />
            </Row>

            <GestaoBloqueioItemList
              entidades={formProps.values.entidades}
              onChange={values =>
                formProps.setFieldValue('entidades', values, true)
              }
              formGestao={formProps.values}
              cliente={formProps.values.cliente}
            />

            <Row></Row>
            <div className="btn-save">
              <FAB icon="check" title="Salvar" onClick={formProps.submitForm} />
            </div>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default GestaoBloqueioFormPage;
